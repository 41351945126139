import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Park from "./models/Park.js";

Vue.config.productionTip = false;

// Localization lib
import i18n from "./i18n";
Vue.use(i18n);

import AFrame from "aframe";
Vue.use(AFrame);
Vue.config.ignoredElements = [
  "a-scene",
  "a-camera",
  "a-sky",
  "a-plane",
  "a-box",
  "a-entity",
  "a-assets",
  "a-asset-item",
];

import VueHtml2Canvas from 'vue-html2canvas';
Vue.use(VueHtml2Canvas);

// leaflet styles
import "leaflet/dist/leaflet.css";

// Service worker
import "./registerServiceWorker";

// Custom global styles
import "@/customStyles/styles.scss";

Number.prototype.toFixedNumber = function (digits, base) {
  var pow = Math.pow(base || 10, digits);
  return Math.round(this * pow) / pow;
};

new Vue({
  router,
  store,
  i18n,
  mounted() {
    let parkEs = new Park(require("@/data/data-es.json"));
    let parkEn = new Park(require("@/data/data-en.json"));
    let parkPt = new Park(require("@/data/data-pt.json"));
    let parkFr = new Park(require("@/data/data-fr.json"));
    this.$store.commit("saveLocalizedParkData", {
      es: parkEs,
      en: parkEn,
      pt: parkPt,
      fr: parkFr,
    });
    this.$i18n.locale = this.$store.state.locale;
  },
  render: (h) => h(App),
}).$mount("#app");
